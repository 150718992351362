export const API_CONSTANTS = {
  tokenGenerate: "authorizationserver/oauth/token",
  customerDetails: "services/v2/mhkflooringBMF/users/{userId}/orgCustomers/{orgCustomerId}",
  builderList:"services/v2/mhkflooringBMF/users/{userId}/builders/{invoiceAccountUnitId}",
  totalOrdersCount: "services/v2/mhkflooringBMF/users/{userId}/builder/{builderUid}/getTotalOrdersCount",
  openOrdersCount: "services/v2/mhkflooringBMF/users/{userId}/builder/{builderUid}/getOpenOrdersCount",
  productsCount: "services/v2/mhkflooringBMF/users/{userId}/countSellingStyle/builder/{builderUid}",
  divisionList: "services/v2/mhkflooringBMF/users/{userId}/divisions/{builderUnitId}",
  subDivisionList: "services/v2/mhkflooringBMF/users/{userId}/subdivisions/{DivisionUnitId}",
  productStyleList: "services/v2/mhkflooringBMF/users/{userId}/searchSellingStyle/invoiceAccount/{InvoiceAccountUid}/builder/{builderUid}/style/{styleNumber_Or_Name}?isPartNumberSearch={isPartNumberSearch}",
  shippingAddessList: "services/v2/mhkflooringBMF/users/{userId}/accounts/getAllShipTo/{accountId}",
  createCart: "services/v2/mhkflooringBMF/users/{userId}/builder/{builderNumber}/create?confirmToCreateNewCart={createConfirm}",
  validateAddress: "services/v2/mhkflooringBMF/users/{userId}/builder/{builderNumber}/create?confirmToCreateNewCart={createConfirm}",
  selectShippingAddress: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/setShippingAddress?addressId={addressId}",
  oneTimeShippingAddress: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/add-one-time-shipTo-address",
  skuList: "services/v2/mhkflooringBMF/users/{userId}/searchSellingSku/style/{styleCode}",
  uomList: "services/v2/mhkflooringBMF/users/{userId}/getUomList/style/{productCode}",
  atp: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/builder/{builderUid}/atp",
  addDivision: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/division/{divisionUid}/addDivisionInfoInCart",
  addSubDivision: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/subdivision/{subDivisionUid}/addSubDivisionInfoInCart?subDivisionFreeText={subDivisionFreeText}",
  addLotInfo: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/builder/{builderUid}/addLotInfo",
  addToCart: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/builder/{builderNumber}/addEntries?code={productCode}",
  orderHistory: "services/v2/mhkflooringBMF/users/{userId}/getOrders?page={page}&sortCode={sortCode}&sortOrder={sortOrder}",
  reserveList: "services/v2/mhkflooringBMF/users/{userId}/getAllUserReserves?fields=DEFAULT",
  getCart: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/viewCart",
  changeQuantity: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/builder/{builderNumber}/changeQuantity",
  changeQuantityAddToOrder: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/builder/{builderNumber}/addEntries",
  cancelLine: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/removeEntry/entries/{entryNumber}",
  cancelCart: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/removeCart",
 eligibilityReserverCart: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/checkEligibilityOfCartForReserve",
  cancelSolution: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/cancelSolution/invoiceAccount/{accountUid}",
  getSubmittedFor: "services/v2/mhkflooringBMF/users/{userId}/getMembersForCart",
  proceedTocheckout:'services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/placeOrderAndGetDetails?fields=DEFAULT',
  proceedToReserver:'services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/addProductsToReserveFromCart?projectName={projectName}',
  updateSidemark:'services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/entries/{entryNumber}/setSideMark?erpOrderNumber={erpOrderNumber}&sideMark={sideMark}',
  setShipAsComplete: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/setShipAsComplete?erpOrderNumber={erpOrderNumber}&shipAsComplete={shipAsComplete}",
  setPO: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/setPo?erpOrderNumber={erpOrderNumber}&poNumber={poNumber}",
  saveComments: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/saveComments?erpOrderNumber={erpOrderNumber}&comment={comment}",
  validateCart: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/validateCart",
  sendOrderLotInfoToCams: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/builder/{builderUid}/sendOrderLotInfoToCams",
  placeOrderAndGetDetails: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/placeOrderAndGetDetails?fields=DEFAULT",
  getShipViaList: 'services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/fetchShipViaList?erpOrderCode={erpOrderCode}&fields=DEFAULT',
  setShipVia: 'services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/saveShipVia?erpOrderCode={erpOrderCode}&shipViaCode={shipViaCode}&shipViaDescription={shipViaDescription}&fields={fields}&freightCode={freightCode}&freightZone={freightZone}&functionType={functionType}',
  getReqDeliveryDates: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/getReqDeliveryDates?erpOrderNumber={erpOrderNumber}&shipComplete={shipComplete}",
  setReqDeliveryDate: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/setReqDeliveryDate?erpOrderNumber={erpOrderNumber}&date={date}",
  orderDetails: 'services/v2/mhkflooringBMF/users/{userId}/orders/orderDetails/{orderCode}',
  getBuilderCart: "services/v2/mhkflooringBMF/users/{userId}/builder/{builderNumber}/getBuilderCart",
  setSubmittedFor: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/setSubmittedFor?submittedForId={uid}",
  createNewUser: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/createSubmittedFor",
  getAccessoriesForEntry: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/getAccessoriesForEntry?entryNum={entryNumber}",
  addMultiAccessoriesToCart: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/builder/{builderNumber}/addMultiAccessoriesToCart",
  todayShipmentOrder:"services/v2/mhkflooringBMF/users/{userId}/getOrders?fields=DEFAULT&page={page}&sortCode={sortCode}&sortOrder={sortOrder}&activeTab=todaysShipmentTab",
  postOrderCancelLine:"services/v2/mhkflooringBMF/users/{userId}/order/{orderCode}/entries/{entryNumber}/cancelOrderEntry?cancellationCode={cancellationCode}&cancellationNotes={cancellationNotes}",
  postOrderCancel:"services/v2/mhkflooringBMF/users/{userId}/order/{orderCode}/cancelOrder?cancellationCode={cancellationCode}&cancellationNotes={cancellationNotes}",
  postOrderGetShipViaList: "services/v2/mhkflooringBMF/users/{userId}/order/{orderCode}/getShipViaList?erpOrderCode={erpOrderCode}",
  postOrderSaveShipVia: "services/v2/mhkflooringBMF/users/{userId}/order/{orderCode}/saveShipViaPostOrder?erpOrderNumber={erpOrderNumber}&shipViaCode={shipViaCode}&shipViaDescription={shipViaDescription}&shipViaCarrier={shipViaCarrier}&freightCode={freightCode}&freightZone={freightZone}&functionType={functionType}",
  postOrderUpdateLotInfo: "services/v2/mhkflooringBMF/users/{userId}/order/{orderCode}/entries/{entryNumber}/updateLotInfo",
  postOrderChangeQuantity: "services/v2/mhkflooringBMF/users/{userId}/changeOrderQuantity",
  postOrderChangeQuantityAddLine: "services/v2/mhkflooringBMF/users/{userId}/orders/addLine?orderCode={orderNumber}&oldLineNumber={entryNumber}&cancellationCode={cancellationCode}",
  postOrderAddNewLineATP: "services/v2/mhkflooringBMF/users/{userId}/orders/atp/addNewLine",
  postOrderAddNewLine: "services/v2/mhkflooringBMF/users/{userId}/orders/addNewLine?orderCode={orderNumber}&productCode={productCode}&quantity={quantity}",
  accessoriesATP: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/builder/{builderUid}/atp",
  getPostOrderDeliveryDates: "services/v2/mhkflooringBMF/order/getDates?erpOrderNumber={erpOrderNumber}&orderCode={orderCode}",
  setPostOrderDeliveryDate: "services/v2/mhkflooringBMF/users/{userId}/changeOrderRequestedDate?erpOrderNumber={erpOrderNumber}&fields=DEFAULT&orderCode={orderCode}&requestedDate={requestedDate}",
  minMaxValidation: "services/v2/mhkflooringBMF/users/{userId}/minMaxValidation?SkuId={SkuId}&fields=DEFAULT&numberOfRolls={numberOfRolls}&targetLength={targetLength}",
  addProductsToReserveFromCart: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/addProductsToReserveFromCart?projectName={projectName}",
  postOrderUpdatePO: "services/v2/mhkflooringBMF/users/{userId}/updatePOForOrder?fields=DEFAULT&orderCode={orderCode}&purchaseOrder={purchaseOrder}&ignoreDuplicates={ignoreDuplicates}",
  downloadPDF: "services/v2/mhkflooringBMF/users/{userId}/orders/downloadPDF?orderCode={orderCode}",
  sharePDF: "services/v2/mhkflooringBMF/users/{userId}/orders/{orderCode}/sharePDF",
  reverseDetail: "services/v2/mhkflooringBMF/users/{userId}/reserve/{reserveNumber}/getReserveDetails",
  addReserveToCart: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/addReservesEntryToCart?fields=DEFAULT&reserveExistingCartConsent={reserveExistingCartConsent}&reserveBuilderCartConsent={reserveBuilderCartConsent}",
  extendReverseLine: "services/v2/mhkflooringBMF/users/{userId}/reserve/extendReserve",
  cancelReverse: "services/v2/mhkflooringBMF/users/{userId}/reserve/cancelReserve",
  postOrderCancelSolution: "services/v2/mhkflooringBMF/users/{userId}/order/cancelSolution",
  excessQuantity: "services/v2/mhkflooringBMF/users/{userId}/product/minMaxValidation?fields=DEFAULT",
  getAccounts: "services/v2/mhkflooringBMF/users/{userId}/listOfB2bUnits?fields=DEFAULT",
  selectAccount: "services/v2/mhkflooringBMF/users/{userId}/accounts/setDefaultUnit/{unitId}?checkSuffixAccount={checkSuffixAccount}",
  csrSearch:"services/v2/mhkflooringBMF/users/{userId}/findAccount/csr?filterTab={tabName}&page={pageNumber}",
  selectCsr:"services/v2/mhkflooringBMF/users/{userId}/selectAccount/csr?accountId={B2BUnitId}",
  exitCsr:"services/v2/mhkflooringBMF/users/{userId}/exitAccount/csr",
  emailList:"services/v2/mhkflooringBMF/users/{userId}/getEmailListAutoComplete",
  notification:"services/v2/mhkflooringBMF/users/{userId}/getPostNotificationResidentialComponentData",
  setDefaultUnitToCSRANDSales: "services/v2/mhkflooringBMF/users/{userId}/orgCustomers/setDefaultUnit",
  salesHierarchy: "services/v2/mhkflooringBMF/users/{userId}/salesHierarchy",
  salesmanOrderHistory: "services/v2/mhkflooringBMF/users/{userId}/getOrders?selectedSalesMan={role}&rvpid={rvpid}&dmid={dmid}&id={id}&onChangeSalesManId={onChangeSalesManId}&page={page}&activeTab={activeTab}&salesManId={salesManId}&sortOrder={sortOrder}&sortCode={sortCode}",
  customers: "rest/v2/us_b2b_residential/users/{userId}/orgCustomers",
  // salesmanAccounts: "services/v2/mhkflooringBMF/users/{userId}/fetchAccountsDetail?&dmid={dmid}&id={id}&resultsPerPage={resultsPerPage}&searchAccounttext={searchAccounttext}",
  salesmanAccounts: "services/v2/mhkflooringBMF/users/{userId}/changeHierarchy/getAccounts?&dmid={dmid}&id={id}&resultsPerPage={resultsPerPage}&searchAccountText={searchAccountText}&selectedSalesMan={selectedSalesMan}&page={page}&sort={sort}&sortBy={sortBy}",
  selectSalesPrsonAccount: "services/v2/mhkflooringBMF/users/{userId}/selectAccount/salesPerson?accountId={accountId}&count={count}&fields=DEFAULT&unitId={unitId}",
  csrSaveCart: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/saveCart",
  csrValidateAccount: "services/v2/mhkflooringBMF/users/{userId}/{invoiceAccount}/validateAccount",
  changeHeirarchy: "services/v2/mhkflooringBMF/users/{userId}/changeHierarchy?selectedSalesMan={selectedSalesMan}&dmid={dmid}&id={id}",
  validateAccount: "services/v2/mhkflooringBMF/users/{userId}/{invoiceAccount}/validateAccount",
  getTermsCodeList: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/termsCodesForSalesPerson",
  getTermsReasonList: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/termsReasonForSalesPerson",
  updateTermsCode: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/entries/{entries}/updateTermsCodeAndDescription?termsCode={termsCode}&termsCodeDescription={termsCodeDescription}",
  updateTermsReason: "services/v2/mhkflooringBMF/users/{userId}/carts/{cartId}/entries/{entries}/updateTermsReasonAndDescription?termsReason={termsReason}&termsReasonDescription={termsReasonDescription}",
  slaesPersonAccountList: "services/v2/mhkflooringBMF/users/{userId}/accounts/getSalesPersonAccounts",
  postOrderUpdateSidemark: "services/v2/mhkflooringBMF/users/{userId}/order/{orderId}/entries/{entryId}/updateSideMark?sideMark={sideMark}",
  getAccessoriesForEntryPostOrder: "services/v2/mhkflooringBMF/users/{userId}/orders/{orderId}/getAccessoriesForEntryInPostOrder?entryNumber={entryNumber}",
  addMultiAccessoriesToCartPostOrder: "services/v2/mhkflooringBMF/users/{userId}/orders/{orderId}/addMultiAccessoriesInPostOrder?isMultiAccessoryInPostOrder={isMultiAccessoryInPostOrder}",
  getActiveBuilders: "services/v2/mhkflooringBMF/users/{userId}/activeBuilders",
  downloadShipmentReport: "services/v2/mhkflooringBMF/users/{userId}/getBuilderShipmentReport?sortCode={sortCode}&sortOrder={sortOrder}",
  getShipmentReport: "services/v2/mhkflooringBMF/users/{userId}/getBuilderShipmentOrders",
  addCushionAccessoriesToCartPostOrder: "services/v2/mhkflooringBMF/users/{userId}/orders/atp/addNewLine",
  asmUserList: "services/v2/mhkflooringBMF/builderCustomers/autocomplete?customerQuery={customerQuery}",
  emulateCustomer: "services/v2/mhkflooringBMF/emulateBuilderCustomer?customerId={customerId}",
  stopSession: "services/v2/mhkflooringBMF/endBuilder-emulation",
  miniCart: "rest/v2/mhkflooringBMF/users/{uid}/{customerNumber}/miniCart",
}