export const INVOICE_HEADER_BLACKLIST_ENDPOINTS = [
  "authorizationserver/oauth/token",
  "services/v2/mhkflooringBMF/users/:userId/findAccount/csr?filterTab=*&page=*",
  "services/v2/mhkflooringBMF/users/:userId/selectAccount/csr?accountId=*",
  "services/v2/mhkflooringBMF/users/:userId/exitAccount/csr",
  "services/v2/mhkflooringBMF/users/:userId/getOrders?selectedSalesMan=*&rvpid=*&dmid=*&id=*&onChangeSalesManId=*&page=*&activeTab=*&salesManId=*&sortOrder=*&sortCode=*",
  "services/v2/mhkflooringBMF/users/:userId/changeHierarchy/getAccounts?&dmid=*&id=*&resultsPerPage=*&searchAccountText=*&selectedSalesMan=*&page=*&sort=*&sortBy=*",
  "services/v2/mhkflooringBMF/users/:userId/changeHierarchy?selectedSalesMan=*&dmid=*&id=*",
  "services/v2/mhkflooringBMF/users/:userId/orgCustomers/:orgCustomerId",
  "services/v2/mhkflooringBMF/users/:userId/orgCustomers/setDefaultUnit",
  "services/v2/mhkflooringBMF/users/:userId/getPostNotificationResidentialComponentData",
  "services/v2/mhkflooringBMF/users/:userId/salesHierarchy",
  "services/v2/mhkflooringBMF/users/:userId/:invoiceAccount/validateAccount",
];

// export const INVOICE_ACCOUNT_HEADER_ENDPOINTS = [
//   // "authorizationserver/oauth/token",
//   "services/v2/mhkflooringBMF/users/:userId/orgCustomers/:orgCustomerId",
//   "services/v2/mhkflooringBMF/users/:userId/builders/:invoiceAccountUnitId",
//   "services/v2/mhkflooringBMF/users/:userId/builder/:builderUid/getTotalOrdersCount",
//   "services/v2/mhkflooringBMF/users/:userId/builder/:builderUid/getOpenOrdersCount",
//   "services/v2/mhkflooringBMF/users/:userId/countSellingStyle/builder/:builderUid",
//   "services/v2/mhkflooringBMF/users/:userId/divisions/:builderUnitId",
//   "services/v2/mhkflooringBMF/users/:userId/subdivisions/:DivisionUnitId",
//   "services/v2/mhkflooringBMF/users/:userId/searchSellingStyle/invoiceAccount/:InvoiceAccountUid/builder/:builderUid/style/:styleNumber_Or_Name",
//   "services/v2/mhkflooringBMF/users/:userId/accounts/getAllShipTo/:accountId",
//   "services/v2/mhkflooringBMF/users/:userId/builder/:builderNumber/create?confirmToCreateNewCart=*",
//   "services/v2/mhkflooringBMF/users/:userId/builder/:builderNumber/create?confirmToCreateNewCart=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/setShippingAddress?addressId=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/add-one-time-shipTo-address",
//   "services/v2/mhkflooringBMF/users/:userId/searchSellingSku/style/:styleCode",
//   "services/v2/mhkflooringBMF/users/:userId/getUomList/style/:productCode",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/builder/:builderUid/atp",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/division/:divisionUid/addDivisionInfoInCart",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/subdivision/:subDivisionUid/addSubDivisionInfoInCart?subDivisionFreeText=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/builder/:builderUid/addLotInfo",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/builder/:builderNumber/addEntries?code=*",
//   "services/v2/mhkflooringBMF/users/:userId/getOrders?page=*&sortCode=*&sortOrder=*",
//   "services/v2/mhkflooringBMF/users/:userId/getAllUserReserves?fields=DEFAULT",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/viewCart",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/builder/:builderNumber/changeQuantity",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/builder/:builderNumber/addEntries",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/removeEntry/entries/:entryNumber",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/removeCart",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/checkEligibilityOfCartForReserve",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/cancelSolution/invoiceAccount/:accountUid",
//   "services/v2/mhkflooringBMF/users/:userId/getMembersForCart",
//   'services/v2/mhkflooringBMF/users/:userId/carts/:cartId/placeOrderAndGetDetails?fields=DEFAULT',
//   'services/v2/mhkflooringBMF/users/:userId/carts/:cartId/addProductsToReserveFromCart?projectName=*',
//   'services/v2/mhkflooringBMF/users/:userId/carts/:cartId/entries/:entryNumber/setSideMark?erpOrderNumber=*&sideMark=*',
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/setShipAsComplete?erpOrderNumber=*&shipAsComplete=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/setPo?erpOrderNumber=*&poNumber=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/saveComments?erpOrderNumber=*&comment=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/validateCart",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/builder/:builderUid/sendOrderLotInfoToCams",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/placeOrderAndGetDetails?fields=DEFAULT",
//   'services/v2/mhkflooringBMF/users/:userId/carts/:cartId/fetchShipViaList?erpOrderCode=*&fields=DEFAULT',
//   'services/v2/mhkflooringBMF/users/:userId/carts/:cartId/saveShipVia?erpOrderCode=*&shipViaCode=*&shipViaDescription=*&fields=*&freightCode=*&freightZone=*&functionType=*',
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/getReqDeliveryDates?erpOrderNumber=*&shipComplete=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/setReqDeliveryDate?erpOrderNumber=*&date=*",
//   'services/v2/mhkflooringBMF/users/:userId/orders/orderDetails/:orderCode',
//   "services/v2/mhkflooringBMF/users/:userId/builder/:builderNumber/getBuilderCart",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/setSubmittedFor?submittedForId=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/createSubmittedFor",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/getAccessoriesForEntry?entryNum=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/builder/:builderNumber/addMultiAccessoriesToCart",
//   "services/v2/mhkflooringBMF/users/:userId/getOrders?fields=DEFAULT&page=*&sortCode=*&sortOrder=*&activeTab=todaysShipmentTab",
//   "services/v2/mhkflooringBMF/users/:userId/order/:orderCode/entries/:entryNumber/cancelOrderEntry?cancellationCode=*",
//   "services/v2/mhkflooringBMF/users/:userId/order/:orderCode/cancelOrder?cancellationCode=*",
//   "services/v2/mhkflooringBMF/users/:userId/order/:orderCode/getShipViaList?erpOrderCode=*",
//   "services/v2/mhkflooringBMF/users/:userId/order/:orderCode/saveShipViaPostOrder?erpOrderNumber=*&shipViaCode=*&shipViaDescription=*&shipViaCarrier=*&freightCode=*&freightZone=*&functionType=*",
//   "services/v2/mhkflooringBMF/users/:userId/order/:orderCode/entries/:entryNumber/updateLotInfo",
//   "services/v2/mhkflooringBMF/users/:userId/changeOrderQuantity",
//   "services/v2/mhkflooringBMF/users/:userId/orders/addLine?orderCode=*&oldLineNumber=*&cancellationCode=*",
//   "services/v2/mhkflooringBMF/users/:userId/orders/atp/addNewLine",
//   "services/v2/mhkflooringBMF/users/:userId/orders/addNewLine?orderCode=*&productCode=*&quantity=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/builder/:builderUid/atp",
//   "services/v2/mhkflooringBMF/order/getDates?erpOrderNumber=*&orderCode=*",
//   "services/v2/mhkflooringBMF/users/:userId/changeOrderRequestedDate?erpOrderNumber=*&fields=DEFAULT&orderCode=*&requestedDate=*",
//   "services/v2/mhkflooringBMF/users/:userId/minMaxValidation?SkuId=*&fields=DEFAULT&numberOfRolls=*&targetLength=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/addProductsToReserveFromCart?projectName=*",
//   "services/v2/mhkflooringBMF/users/:userId/updatePOForOrder?fields=DEFAULT&orderCode=*&purchaseOrder=*&ignoreDuplicates=*",
//   "services/v2/mhkflooringBMF/users/:userId/orders/downloadPDF?orderCode=*",
//   "services/v2/mhkflooringBMF/users/:userId/orders/:orderCode/sharePDF",
//   "services/v2/mhkflooringBMF/users/:userId/reserve/:reserveNumber/getReserveDetails",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/addReservesEntryToCart?fields=DEFAULT&reserveExistingCartConsent=*&reserveBuilderCartConsent=*",
//   "services/v2/mhkflooringBMF/users/:userId/reserve/extendReserve",
//   "services/v2/mhkflooringBMF/users/:userId/reserve/cancelReserve",
//   "services/v2/mhkflooringBMF/users/:userId/order/cancelSolution",
//   "services/v2/mhkflooringBMF/users/:userId/product/minMaxValidation?fields=DEFAULT",
//   "services/v2/mhkflooringBMF/users/:userId/listOfB2bUnits?fields=DEFAULT",
//   "services/v2/mhkflooringBMF/users/:userId/accounts/setDefaultUnit/:unitId?checkSuffixAccount=*",
//   // "services/v2/mhkflooringBMF/users/:userId/findAccount/csr?filterTab=*&page=*",
//   // "services/v2/mhkflooringBMF/users/:userId/selectAccount/csr?accountId=*",
//   // "services/v2/mhkflooringBMF/users/:userId/exitAccount/csr",
//   "services/v2/mhkflooringBMF/users/:userId/getEmailListAutoComplete",
//   "services/v2/mhkflooringBMF/users/:userId/getPostNotificationResidentialComponentData",
//   "services/v2/mhkflooringBMF/users/:userId/orgCustomers/setDefaultUnit",
//   "services/v2/mhkflooringBMF/users/:userId/salesHierarchy",
//   // "services/v2/mhkflooringBMF/users/:userId/getOrders?selectedSalesMan=*&rvpid=*&dmid=*&id=*&onChangeSalesManId=*&page=*&activeTab=*&salesManId=*&sortOrder=*&sortCode=*",
//   "rest/v2/us_b2b_residential/users/:userId/orgCustomers",
//   // "services/v2/mhkflooringBMF/users/:userId/changeHierarchy/getAccounts?&dmid=*&id=*&resultsPerPage=*&searchAccountText=*&selectedSalesMan=*&page=*&sort=*&sortBy=*",
//   "services/v2/mhkflooringBMF/users/:userId/selectAccount/salesPerson?accountId=*&count=*&fields=DEFAULT&unitId=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/saveCart",
//   "services/v2/mhkflooringBMF/users/:userId/:invoiceAccount/validateAccount",
//   // "services/v2/mhkflooringBMF/users/:userId/changeHierarchy?selectedSalesMan=*&dmid=*&id=*",
//   "services/v2/mhkflooringBMF/users/:userId/:invoiceAccount/validateAccount",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/termsCodesForSalesPerson",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/termsReasonForSalesPerson",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/entries/:entries/updateTermsCodeAndDescription?termsCode=*&termsCodeDescription=*",
//   "services/v2/mhkflooringBMF/users/:userId/carts/:cartId/entries/:entries/updateTermsReasonAndDescription?termsReason=*&termsReasonDescription=*",
// ];