import {
  Attribute,
  Directive,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Eventservice } from '../../http-services/events.service';

@Directive({
  selector: '[captureClicks]',
})
export class CaptureClicksDirective {
  @Input() routerLink?: string | any[];

  @Input() linkContainer?: string;
  @Input() linkId?: string;

  constructor(private eventservice: Eventservice) {}

  static getAnchor(target: any) {
    while (target) {
      if (target instanceof HTMLAnchorElement) {
        return target;
      }
      target = target.parentNode;
    }
    return null;
  }

  @HostListener('click', ['$event'])
  handleClickLink(event: MouseEvent): void {
    event.preventDefault();

    const target: any = event.target;
    const href = target && target.getAttribute('href');

    if (!!this.routerLink) {
      this.eventservice.internalLinkClicked({
        linkContainer: this.linkContainer || '',
        linkId: this.linkId || target.innerText || '',
        linkTarget: this.routerLink || href || '',
      });
      return;
    } else {
      this.eventservice.externalLinkClicked({
        linkContainer: this.linkContainer || '',
        linkId: this.linkId || target.innerText || '',
        linkTarget: href || this.routerLink || '',
      });
      window.open(href, '_blank', 'noopener, noreferrer');
      return;
    }
  }
}
