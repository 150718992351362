import { Injectable } from '@angular/core';
import { map, of, switchMap } from 'rxjs';
import { TokenService } from 'src/app/features/http-services/token.service';
import { ApiService } from '../../../http-services/api.service';
import { StorageService } from '../../../http-services/storage.service';
import { API_CONSTANTS } from '../../../shared/constants/api';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  USERNAME = "purushottamb@mohawk.com";
  PASSWORD = "Mohawk@1";
  CUSTOMER_DETAILS_KEY = "customer-details";
  userEmail: any;

  constructor(
    private apiService: ApiService,
    private tokenService: TokenService,
    private storageService: StorageService
  ) { }

  login(payload: object = {
    username: this.USERNAME,
    password: this.PASSWORD
  }) {
    //return this.tokenService.generateToken(payload, );
  }

  getCustomerDetails({
    addInIndexDB = false,
    username = '',
  }) {
    const endpoint = API_CONSTANTS.customerDetails
    .replace("{userId}", username)
    .replace("{orgCustomerId}", username);
    return this.apiService.get(endpoint).pipe(
      map(({ body }: any) => {
        addInIndexDB && this.storageService.setItem(this.CUSTOMER_DETAILS_KEY, body);
        return body;
      })
    );
  }

  getBuilderListing(invoiceAccountUnitId : string, userId: any) {
    const endpoint = API_CONSTANTS.builderList
      .replace("{userId}", userId)
      .replace("{invoiceAccountUnitId}", invoiceAccountUnitId);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  getActiveBuilderList(data: any) {
    const endpoint = API_CONSTANTS.getActiveBuilders
      .replace("{userId}", data.userId);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  setUserEmail(userEmail: string) {
    this.userEmail = userEmail;
    localStorage.setItem("userEmail", userEmail);
  }

  getUserEmail() {
    if (this.userEmail === undefined) {
      this.userEmail = localStorage.getItem("userEmail");
      return this.userEmail;
    } else {
      return this.userEmail;
    }
  }

  getDefaultPath() {
    let condition = true;
    if (condition){
      return of("/");
    }
    else return of ("/");
  }

  getBuilderCart(data: any) {
    const endpoint = API_CONSTANTS.getBuilderCart
      .replace("{userId}", data.userId)
      .replace("{builderNumber}", data.builderNumber);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  getAccounts(data: any) {
    const endpoint = API_CONSTANTS.getAccounts
      .replace("{userId}", data.userId);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  setDefaultUnit(data:any, payload: any = {}){
    const endpoint = API_CONSTANTS.setDefaultUnitToCSRANDSales.replace("{userId}", data.username)
    return this.apiService.patch(endpoint, payload).pipe(map(({ body }: any) => body));
  }
}
