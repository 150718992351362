import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../shared/utils/window';
import { timer } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as Events from '../shared/interfaces/events.interface';

interface WindowRef extends Window {
  appEventData: Array<Events.DataLayerEvent>;
}

@Injectable({
  providedIn: 'root',
})
export class Eventservice {
  private interceptCompletion: string = '';
  private lastCartViewed: {
    cart: {
      submittedFor: string;
      shipVia: string;
      entries: Array<{
        sku: string;
        styleName: string;
        styleNumber: string;
        colorName: string;
        colorNumber: string;
        productType: string;
        quantity: number;
        uom: string;
        pricePerUnit: number | null;
        backingName: string;
        size: string;
        salesperson: string;
        termsCode: string;
      }>;
      subTotal: string;
      totalFreightCharges: string;
      totalMiscCharges: string;
      totalTax: string;
      totalPriceWithTax: string;
    };
    builderInfo: {
      builderNumber: string;
      builderCity: string;
      builderState: string;
      divCity: string;
      divState: string;
      subDivCity: string;
      subDivState: string;
    };
  } = {
    cart: {
      submittedFor: '',
      shipVia: '',
      entries: [
        {
          sku: '',
          styleName: '',
          styleNumber: '',
          colorName: '',
          colorNumber: '',
          productType: '',
          quantity: 0,
          uom: '',
          pricePerUnit: null,
          backingName: '',
          size: '',
          salesperson: '',
          termsCode: '',
        },
      ],
      subTotal: '',
      totalFreightCharges: '',
      totalMiscCharges: '',
      totalTax: '',
      totalPriceWithTax: '',
    },
    builderInfo: {
      builderNumber: '',
      builderCity: '',
      builderState: '',
      divCity: '',
      divState: '',
      subDivCity: '',
      subDivState: '',
    },
  };
  private lastEvent: Events.DataLayerEvent = {
    event: 'Page Load Completed',
  };
  constructor(@Inject(WINDOW) readonly windowRef: Window) {}

  /**
   * Push to Data Layer
   * @param {Events.DataLayerEvent} event - The event to be pushed to the data layer.
   * @returns void
   */
  private pushToDataLayer(event: Events.DataLayerEvent) {
    (this.windowRef as WindowRef)['appEventData'] =
      (this.windowRef as WindowRef)['appEventData'] || [];
    (this.windowRef as WindowRef)['appEventData'].push(event);
    this.lastEvent = event;
  }

  /**
   * Builder Selected Event
   * @param {Object} params - The parameters provided to the function.
   * @returns void
   */
  public builderSelected(params: {
    builderSelected: {
      builderNumber: string;
      builderName: string;
    };
    buildersListed: Array<{
      builderNumber: string;
      builderName: string;
    }>;
  }) {
    const event: Events.BuilderSelected = {
      event: 'Builder Selected',
      ...params,
    };
    this.pushToDataLayer(event);
  }

  /**
   * Cart Viewed Event
   * @param {Object} params - The parameters provided to the function.
   * @param {Object} params.cart - Data corresponding to the cart being viewed.
   * @param {Object} params.builderInfo - Data corresponding to the builder attached to the order being viewed.
   */
  public cartViewed(params: {
    cart: {
      submittedFor: string;
      shipVia: string;
      entries: Array<{
        sku: string;
        styleName: string;
        styleNumber: string;
        colorName: string;
        colorNumber: string;
        productType: string;
        quantity: number;
        uom: string;
        pricePerUnit: number | null;
        backingName: string;
        size: string;
        salesperson: string;
        termsCode: string;
      }>;
      subTotal: string;
      totalFreightCharges: string;
      totalMiscCharges: string;
      totalTax: string;
      totalPriceWithTax: string;
    };
    builderInfo: {
      builderNumber: string;
      builderCity: string;
      builderState: string;
      divCity: string;
      divState: string;
      subDivCity: string;
      subDivState: string;
    };
  }) {
    const event: Events.CartViewed = {
      event: 'Cart Viewed',
      ...params,
    };
    this.lastCartViewed = params;
    this.pushToDataLayer(event);
  }

  public checkoutStarted(params: {
    cart: {
      submittedFor: string;
      shipVia: string;
      entries: Array<{
        sku: string;
        styleName: string;
        styleNumber: string;
        colorName: string;
        colorNumber: string;
        productType: string;
        quantity: number;
        uom: string;
        pricePerUnit: number | null;
        backingName: string;
        size: string;
        salesperson: string;
        termsCode: string;
      }>;
      subTotal: string;
      totalFreightCharges: string;
      totalMiscCharges: string;
      totalTax: string;
      totalPriceWithTax: string;
    };
    builderInfo: {
      builderNumber: string;
      builderCity: string;
      builderState: string;
      divCity: string;
      divState: string;
      subDivCity: string;
      subDivState: string;
    };
  }) {
    const event: Events.CheckoutStarted = {
      event: 'Checkout Started',
      ...params,
    };
    this.pushToDataLayer(event);
  }

  /**
   * Link Clicked Event
   * @param {Object} linkContainer - The container of the link.
   * @param {Object} linkId - The ID of the link - usually its label.
   * @param {Object} linkTarget - The destination of the link.
   */
  public externalLinkClicked({
    linkContainer,
    linkId,
    linkTarget,
  }: {
    linkContainer: string;
    linkId: string;
    linkTarget: string;
  }) {
    const event: Events.ExternalLinkClicked = {
      event: 'External Link Clicked',
      linkInfo: {
        linkContainer,
        linkId,
        linkTarget,
      },
    };
    this.pushToDataLayer(event);
  }

  /**
   * Link Clicked Event
   * @param {Object} linkContainer - The container of the link.
   * @param {Object} linkId - The ID of the link - usually its label.
   * @param {Object} linkTarget - The destination of the link.
   */
  public internalLinkClicked({
    linkContainer,
    linkId,
    linkTarget,
  }: {
    linkContainer: string;
    linkId: string;
    linkTarget: string;
  }) {
    const event: Events.InternalLinkClicked = {
      event: 'Internal Link Clicked',
      linkInfo: {
        linkContainer,
        linkId,
        linkTarget,
      },
    };
    this.pushToDataLayer(event);
  }

  /**
   * Listing Filter Added Event
   * @param {Object} params - The parameters provided to the function.
   * @param {string} params.listingType - The type of listings shown.
   * @param {string} params.filterList - A twice-delimited list of all currently-selected filters. Each filter is shown as filterName~value, and separated by |.
   */
  public listingFilterAdded(params: {
    listingType: string;
    filterList: string;
  }) {
    const event: Events.ListingFilterAdded = {
      event: 'Listing Filter Added',
      listingRefined: params,
    };
    this.pushToDataLayer(event);
  }

  /**
   * Listing Filter Removed Event
   * @param {Object} params - The parameters provided to the function.
   * @param {string} params.listingType - The type of listings shown.
   * @param {string} params.filterList - A twice-delimited list of all filters being removed. Each filter is shown as filterName~value, and separated by |.
   */
  public listingFilterRemoved(params: {
    listingType: string;
    filterList: string;
  }) {
    const event: Events.ListingFilterRemoved = {
      event: 'Listing Filter Removed',
      listingRefined: params,
    };
    this.pushToDataLayer(event);
  }

  /**
   * Onsite Search Performed Event
   * @param {string} params.searchTerm - The term searched by the visitor.
   * @param {string} params.searchType - The type of data being searched.
   */
  public onsiteSearchPerformed({
    searchTerm,
    searchType,
  }: {
    searchTerm: string;
    searchType: string;
  }) {
    const event: Events.OnsiteSearchPerformed = {
      event: 'Onsite Search Performed',
      onsiteSearch: {
        keyword: {
          searchTerm,
          searchType,
        },
      },
    };
    this.pushToDataLayer(event);
  }

  /**
   * Order Listing Displayed Event
   * @param {Array<Object>} params - The parameters provided to the function.
   */
  public orderListingDisplayed(
    params: Array<{
      orderNumber: string;
      orderDate: string;
      poNumber: string;
      reqDelDate: string;
      eddMessage: string;
      status: string;
      sidemark: string;
    }>
  ) {
    const event: Events.OrderListingDisplayed = {
      event: 'Order Listing Displayed',
      listingDisplayed: params,
    };
    if (event !== this.lastEvent) {
      this.pushToDataLayer(event);
    }
  }

  public orderPlaced(params: {
    order: {
      orderNumber: string;
      status: string;
      poNumber: string;
      orderDate: string;
      comments: string;
      reqDelDate: string;
      eddMessage: string;
      shipVia: string;
      entries: Array<{
        sku: string;
        styleName: string;
        styleNumber: string;
        colorName: string;
        colorNumber: string;
        productType: string;
        quantity: number;
        uom: string;
        pricePerUnit: number | null;
        backingName: string;
        size: string;
        salesperson: string;
        termsCode: string;
        status: string;
        sidemark: string;
        availableForPickup: boolean;
      }>;
      subTotal: string;
      totalFreightCharges: string;
      totalMiscCharges: string;
      totalTax: string;
      totalPriceWithTax: string;
    };
    builderInfo: {
      builderNumber: string;
      builderCity: string;
      builderState: string;
      divCity: string;
      divState: string;
      subDivCity: string;
      subDivState: string;
    };
  }) {
    const event: Events.OrderPlaced = {
      event: 'Order Placed',
      ...params,
    };
    this.pushToDataLayer(event);
  }

  /**
   * Order Viewed Event
   * @param {Object} params - The parameters provided to the function.
   * @param {Object} params.order - Data corresponding to the orders being viewed.
   * @param {Object} params.builderInfo - Data corresponding to the builder attached to the order being viewed.
   * @returns void
   */
  public orderViewed(params: {
    order: {
      orderNumber: string;
      status: string;
      poNumber: string;
      orderDate: string;
      comments: string;
      reqDelDate: string;
      eddMessage: string;
      shipVia: string;
      entries: Array<{
        sku: string;
        styleName: string;
        styleNumber: string;
        colorName: string;
        colorNumber: string;
        productType: string;
        quantity: number;
        uom: string;
        pricePerUnit: number | null;
        backingName: string;
        size: string;
        salesperson: string;
        termsCode: string;
        status: string;
        sidemark: string;
        availableForPickup: boolean;
      }>;
      subTotal: string;
      totalFreightCharges: string;
      totalMiscCharges: string;
      totalTax: string;
      totalPriceWithTax: string;
    };
    builderInfo: {
      builderNumber: string;
      builderCity: string;
      builderState: string;
      divCity: string;
      divState: string;
      subDivCity: string;
      subDivState: string;
    };
  }) {
    const event: Events.OrderViewed = {
      event: 'Order Viewed',
      ...params,
    };
    this.pushToDataLayer(event);
  }

  /**
   * Page Load Completed Event
   * @returns void
   */
  public pageComplete() {
    (this.windowRef as WindowRef)['appEventData'] =
      (this.windowRef as WindowRef)['appEventData'] || [];
    const event: Events.PageLoadCompleted = {
      event: 'Page Load Completed',
    };
    (this.windowRef as WindowRef)['appEventData'].push(event);
  }

  /**
   * Page Load Started Event
   * @param {Object} params - The parameters provided to the function.
   * @param {string} params.pageName - The name of the page being loaded.
   * @param {string} params.pageType - The type of page being loaded.
   * @returns void
   */
  public pageLoadStarted(params: { pageName: string; pageType: string }) {
    if (
      (this.windowRef as WindowRef)['appEventData']?.length >= 1 &&
      (this.windowRef as WindowRef)['appEventData']
        .slice(-4)
        .find(({ event }: any) => event === 'Page Load Completed') === undefined
    ) {
      this.pageComplete();
      this.interceptCompletion = params.pageName;
    }
    const event: Events.PageLoadStarted = {
      event: 'Page Load Started',
      page: {
        pageName: params?.pageName,
        pageType: params?.pageType,
        siteName: 'BXchange',
        siteLanguage: 'en-us',
        siteCountry: 'US',
      },
    };
    this.pushToDataLayer(event);
  }

  /**
   * Product Added to Cart
   * @param {Object} params - The parameters provided to the function.
   */
  public productAddedToCart(params: {
    sku: string;
    styleName: string;
    styleNumber: string;
    colorName: string;
    colorNumber: string;
    productType: string;
    quantity: number;
    uom: string;
    pricePerUnit: number | null;
    backingName: string;
    size: string;
    salesperson: string;
    termsCode: string;
  }) {
    const event: Events.ProductAddedToCart = {
      event: 'Product Added to Cart',
      product: {
        sku: params.sku,
        styleName: params.styleName,
        styleNumber: params.styleNumber,
        colorName: params.colorName,
        colorNumber: params.colorNumber,
        productType: params.productType,
        quantity: params.quantity,
        uom: params.uom,
        pricePerUnit: params.pricePerUnit,
        backingName: params.backingName,
        size: params.size,
        salesperson: params.salesperson,
        termsCode: params.termsCode,
      },
    };
    this.pushToDataLayer(event);
  }

  /**
   * Product Removed from Cart
   * @param {Object} params - The parameters provided to the function.
   */
  public productRemovedFromCart(
    params: Array<{
      sku: string;
      styleName: string;
      styleNumber: string;
      colorName: string;
      colorNumber: string;
      productType: string;
      quantity: number;
      uom: string;
      pricePerUnit: number | null;
      backingName: string;
      size: string;
      salesperson: string;
      termsCode: string;
    }>
  ) {
    const event: Events.ProductRemovedFromCart = {
      event: 'Product Removed from Cart',
      product: params,
    };
    this.pushToDataLayer(event);
  }

  /**
   * Reserve Listing Displayed
   * @param {Object} params.listingDisplayed - The listings shown.
   */
  public reserveListingDisplayed(params: {
    listingDisplayed: Array<{
      reserveNumber: string;
      reserveName: string;
      reservedBy: string;
    }>;
  }) {
    const event: Events.ReserveListingDisplayed = {
      event: 'Reserve Listing Displayed',
      ...params,
    };
    this.pushToDataLayer(event);
  }

  /**
   * Reserve Viewed
   * @param {Object} params - The paramaters provided to the function
   * @returns void
   */
  public reserveViewed(params: {
    reserve: {
      reserveNumber: string;
      reserveName: string;
      reservedBy: string;
      status: string;
      shipVia: string;
      entries: [
        {
          sku: string;
          styleName: string;
          styleNumber: string;
          colorName: string;
          colorNumber: string;
          productType: string;
          quantity: number;
          uom: string;
          pricePerUnit: number | null;
          backingName: string;
          size: string;
          salesperson: string;
          termsCode: string;
        }
      ];
    };
    builderInfo: {
      builderNumber: string;
      builderCity: string;
      builderState: string;
      divCity: string;
      divState: string;
      subDivCity: string;
      subDivState: string;
    };
  }) {
    const event: Events.ReserveViewed = {
      event: 'Reserve Viewed',
      reserve: params.reserve,
      builderInfo: params.builderInfo,
    };
    this.pushToDataLayer(event);
  }

  /**
   * Reserve Placed
   * @param params - The parameters provided to the function.
   */
  public reservePlaced(params: { reserveNumber: string; reserveName: string }) {
    const event: Events.ReservePlaced = {
      event: 'Reserve Placed',
      ...this.lastCartViewed,
      reserve: {
        reservedBy: this.lastCartViewed?.cart?.submittedFor || '',
        ...params,
      },
    };
    this.pushToDataLayer(event);
  }

  /**
   * Timed Page Load Completed Function
   * @param {Object} params - The parameters provided to the function.
   * @param {string} params.title - The title of the page which has been loaded.
   * @returns void
   */
  public timedPageComplete(params: any) {
    const { title } = params;
    if (this.interceptCompletion !== title) {
      timer(3000)
        .pipe(delay(3000))
        .subscribe(() => {
          this.pageComplete();
        });
    }
  }
}
