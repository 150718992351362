import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/features/http-services/api.service';
import { API_CONSTANTS } from 'src/app/features/shared/constants/api';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private apiService: ApiService
  ) { }

  getDivisionList(payload: any) {
    const endpoint = API_CONSTANTS.divisionList
      .replace("{userId}", payload.username)
      .replace("{builderUnitId}", payload.builderUid);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  getSubDivisionList(payload: any) {
    const endpoint = API_CONSTANTS.subDivisionList
      .replace("{userId}", payload.username)
      .replace("{DivisionUnitId}", payload.divisionUnitId);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  getProductStyleList(payload: any) {
    const endpoint = API_CONSTANTS.productStyleList
      .replace("{userId}", payload.username)
      .replace("{builderUid}", payload.builderUid)
      .replace("{InvoiceAccountUid}", payload.invoiceAccountUid)
      .replace("{styleNumber_Or_Name}", payload.styleName)
      .replace("{isPartNumberSearch}", payload.isPartNumberSearch);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  getSKUList(payload: any, data: any = {}) {
    let endpoint = API_CONSTANTS.skuList
      .replace("{userId}", payload.username)
      .replace("{styleCode}", payload.styleCode);
      if (data.isPartNumberSearch) {
        endpoint += `?partNumber=${data.partNumber || ""}`;
      }
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  getUOMList(payload: any) {
    const endpoint = API_CONSTANTS.uomList
      .replace("{userId}", payload.username)
      .replace("{productCode}", payload.productCode);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  createATP(data: any, payload: any) {
    const endpoint = API_CONSTANTS.atp
      .replace("{userId}", data.username)
      .replace("{cartId}", data.cartId)
      .replace("{builderUid}", data.builderUid);
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }

  addToCart(data: any, payload: any) {
    const endpoint = API_CONSTANTS.addToCart
      .replace("{userId}", data.username)
      .replace("{cartId}", data.cartId)
      .replace("{builderNumber}", data.builderNumber)
      .replace("{productCode}", data.productCode);
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }

  addDivision(payload: any) {
    const endpoint = API_CONSTANTS.addDivision
      .replace("{userId}", payload.username)
      .replace("{cartId}", payload.cartId)
      .replace("{divisionUid}", payload.divisionUid);
    return this.apiService.put(endpoint, payload);
  }

  addSubDivision(payload: any) {
    const endpoint = API_CONSTANTS.addSubDivision
      .replace("{userId}", payload.username)
      .replace("{cartId}", payload.cartId)
      .replace("{subDivisionUid}", payload.subDivisionUid)
      .replace("{subDivisionFreeText}", payload.subDivisionFreeText);
    return this.apiService.put(endpoint, payload);
  }

  addLotInfo(data: any, payload: any) {
    const endpoint = API_CONSTANTS.addLotInfo
      .replace("{userId}", data.username)
      .replace("{cartId}", data.cartId)
      .replace("{builderUid}", data.builderUid);
    return this.apiService.post(endpoint, payload);
  }

  getCart(payload: any) {
    const endpoint = API_CONSTANTS.getCart
      .replace("{userId}", payload.username)
      .replace("{cartId}", payload.cartId);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }
  getdownloadPDF(data: any) {
    const endpoint = API_CONSTANTS.downloadPDF
      .replace("{userId}", data.username)
      .replace("{orderCode}", data.orderCode)
    return this.apiService.get(endpoint, { responseType: 'blob' }).pipe(map(({ body }) => body));
  }

  sharePDF(data:any, payload:any){
    const endpoint = API_CONSTANTS.sharePDF
    .replace("{userId}", data.username)
    .replace("{orderCode}",data.orderCode)
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }

  getSubmittedFor(payload: any) {
    const endpoint = API_CONSTANTS.getSubmittedFor
      .replace("{userId}", payload.username);
    return this.apiService.get(endpoint).pipe(map(({ body }) => body));
  }

  getReserverEligility(payload: any) {
    const endpoint = API_CONSTANTS.eligibilityReserverCart
      .replace("{userId}", payload.username)
      .replace("{cartId}", payload.cartId);
    return this.apiService.get(endpoint).pipe(map(({ body }: any) => body));
  }

  changeQuantity(data: any, payload: any) {
    const endpoint = API_CONSTANTS.changeQuantity
      .replace("{userId}", data.username)
      .replace("{cartId}", data.cartId)
      .replace("{builderNumber}", data.builderNumber);
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }

  changeQuantityAddToCart(data: any, payload: any) {
    const endpoint = API_CONSTANTS.changeQuantityAddToOrder
      .replace("{userId}", data.username)
      .replace("{cartId}", data.cartId)
      .replace("{builderNumber}", data.builderNumber);
    return this.apiService.post(`${endpoint}?code=${payload?.productCode}`, payload).pipe(map(({ body }: any) => body));
  }

  cancelLine(payload: any) {
    const endpoint = API_CONSTANTS.cancelLine
      .replace("{userId}", payload.username)
      .replace("{cartId}", payload.cartId)
      .replace("{entryNumber}", payload.entryNumber);
    return this.apiService.delete(endpoint).pipe(map(({ body }: any) => body));
  }

  cancelCart(payload: any) {
    const endpoint = API_CONSTANTS.cancelCart
      .replace("{userId}", payload.username)
      .replace("{cartId}", payload.cartId);
    return this.apiService.get(endpoint).pipe(map(({ body }: any) => body));
  }

  

  
  cancelSolution(data: any, payload: any) {
    const endpoint = API_CONSTANTS.cancelSolution
      .replace("{userId}", data.username)
      .replace("{cartId}", data.cartId)
      .replace("{accountUid}", data.accountUid);
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }
  proceedToCheckout(data: any, payload: any) {
    const endpoint = API_CONSTANTS.proceedTocheckout
      .replace("{userId}", data.username)
      .replace("{cartId}", data.cartId);
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }
  proceedToReserve(data: any, payload: any) {
    const endpoint = API_CONSTANTS.proceedTocheckout
      .replace("{userId}", data.username)
      .replace("{cartId}", data.cartId);
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }


  updatePostOrderLotInfo(data: any, payload: any) {
    const endpoint = API_CONSTANTS.postOrderUpdateLotInfo
      .replace("{userId}", data.username)
      .replace("{orderCode}", data.orderCode)
      .replace("{entryNumber}", data.entryNumber);
    return this.apiService.put(endpoint, payload);
  }

  getOrderDetails(data: any) {
    const endpoint = API_CONSTANTS.orderDetails
      .replace("{userId}", data.username)
      .replace("{orderCode}", data.orderCode)
    return this.apiService.post(endpoint, null).pipe(map(({ body }: any) => body));
  }

  postOrderCancel(data: any) {
    const endpoint = API_CONSTANTS.postOrderCancel
      .replace("{userId}", data.username)
      .replace("{orderCode}", data.orderCode)
      .replace("{cancellationCode}", data.cancellationCode)
      .replace("{cancellationNotes}", data.cancellationNotes)
    return this.apiService.put(endpoint, {});
  }

  postOrderCancelLine(data: any) {
    const endpoint = API_CONSTANTS.postOrderCancelLine
      .replace("{userId}", data.username)
      .replace("{orderCode}", data.orderCode)
      .replace("{entryNumber}", data.entryNumber)
      .replace("{cancellationCode}", data.cancellationCode)
      .replace("{cancellationNotes}", data.cancellationNotes)
    return this.apiService.put(endpoint, {});
  }

  postOrderAddNewLineATP(data: any, payload: any = {}) {
    const endpoint = API_CONSTANTS.postOrderAddNewLineATP
      .replace("{userId}", data.username);
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }

  postOrderAddNewLine(data: any, payload: any = {}) {
    const endpoint = API_CONSTANTS.postOrderAddNewLine
      .replace("{userId}", data.username)
      .replace("{orderNumber}", data.orderNumber)
      .replace("{productCode}", data.productCode)
      .replace("{quantity}", data.quantity)
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }

  postOrderChangeQuantity(data: any, payload: any = {}) {
    const endpoint = API_CONSTANTS.postOrderChangeQuantity
      .replace("{userId}", data.username)
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }


  postOrderChangeQuantityAddLine(data: any, payload: any = {}) {
    const endpoint = API_CONSTANTS.postOrderChangeQuantityAddLine
      .replace("{userId}", data.username)
      .replace("{orderNumber}", data.orderNumber)
      .replace("{entryNumber}", data.entryNumber)
      .replace("{cancellationCode}", data.cancellationCode)
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }

  getMinMaxValidation(data: any) {
    const endpoint = API_CONSTANTS.minMaxValidation
      .replace("{userId}", data.username)
      .replace("{SkuId}", data.SkuId)
      .replace("{numberOfRolls}", data.numberOfRolls)
      .replace("{targetLength}", data.targetLength)
    return this.apiService.post(endpoint, null).pipe(map(({ body }: any) => body));
  }

  postOrderCancelSolution(data: any, payload: any) {
    const endpoint = API_CONSTANTS.postOrderCancelSolution
      .replace("{userId}", data.username);
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }

  getExcessQuantityLimit(data: any, payload: any) {
    const endpoint = API_CONSTANTS.excessQuantity
      .replace("{userId}", data.username);
    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }

  getEmailList(data:any){
    const endpoint = API_CONSTANTS.emailList
      .replace("{userId}", data.username)
  return this.apiService.get(endpoint).pipe(map(({ body }) => body));

  }
}