import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Eventservice } from 'src/app/features/http-services/events.service';

@Component({
  selector: 'app-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrls: ['./authorized-layout.component.scss'],
})
export class AuthorizedLayoutComponent implements OnInit {
  routeWithoutHeaderAndFooter = [];
  routeWithoutBuilderInfo = ['select-builder', 'csr', 'multiple-accounts'];
  showHeader: boolean = true;
  showFooter: boolean = true;
  showBuilderInfo: boolean = true;

  constructor(private router: Router, private eventservice: Eventservice) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(this.handleHeaderFooterVisibility);
  }

  handleHeaderFooterVisibility = (event: any) => {
    this.eventservice.pageLoadStarted({
      pageName: `BXchange${
        event?.url?.length > 1
          ? event.url.replaceAll('/', ':').split('?')[0]
          : ':Home Page'
      }`,
      pageType: event.url.split('/')[1] || 'Home Page',
    });
    const shouldHideHeaderAndFooter = this.routeWithoutHeaderAndFooter.some(
      (item) => (event?.url || '').includes(item)
    );
    const shouldHideBuilderInfo = this.routeWithoutBuilderInfo.some((item) =>
      (event?.url || '').includes(item)
    );
    this.showHeader = !shouldHideHeaderAndFooter;
    this.showFooter = !shouldHideHeaderAndFooter;
    this.showBuilderInfo = !shouldHideBuilderInfo;
    this.eventservice.timedPageComplete({
      title: 'BXchange' + event.url.replaceAll('/', ':').split('?')[0],
    });
  };

  ngOnInit(): void {}
}
