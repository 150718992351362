import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map, Observable, of, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { API_CONSTANTS } from "../shared/constants/api";
import { ApiService } from "./api.service";
import { StorageService } from "./storage.service";

@Injectable({ providedIn: "root" })
export class TokenService {

  TOKEN_KEY: string = 'token';
  TOKEN_EXPIRE_KEY: string = 'tokenExpires';

  clearStorage() {
    localStorage.removeItem(this.TOKEN_KEY);
    this.storageService$.removeItem("shipping-address");
    this.storageService$.removeItem("selected-builder");
    this.storageService$.removeItem("cart-details");
    this.storageService$.removeItem("cart");
    this.storageService$.removeItem("customer-details");
    this.storageService$.removeItem("cliam-order");
    this.storageService$.removeItem("is-multi-accounts");
    this.storageService$.removeItem("filterValueOne");
    this.storageService$.removeItem("salesman-selected");
    this.storageService$.removeItem("salesman-order-filter");
        /*this.storageService$.setItem(StorageKeys.searchAndMakeRep, false);
        this.storageService$.setItem(StorageKeys.searchByName, false);
        this.storageService$.setItem(StorageKeys.searchMakeRepValue, '');
        this.storageService$.setItem(StorageKeys.searchNameValue, '');*/
      // sessionStorage.removeItem(this.TOKEN_EXPIRE_KEY);
      localStorage.removeItem(this.TOKEN_EXPIRE_KEY);
  }

  logout() {
    console.log("logoutttttt")
    // sessionStorage.removeItem(this.TOKEN_KEY);
   this.clearStorage();
     window.location.href = environment.openAMUrl + environment.hostURL;
  }
  constructor(
    private http: HttpClient,
    private router: Router,
    private apiService: ApiService,
    private storageService$: StorageService,
  ) { }

  redirectToLogin(currentURL: string) {
    this.clearStorage();
    this.storeCurrentLocation(currentURL);
    window.location.href = environment.openAMRedirect +
      environment.hostURL + "#login";
  }

  storeCurrentLocation(currentURL: string) {
    sessionStorage.setItem("redirectURL", currentURL);
  }

  // generateToken(payload: any = {}) {	
  //   const url= `${API_CONSTANTS.tokenGenerate}?grant_type=password&username=${payload.username}&password=${payload.password}`;	
  //   const headers = new HttpHeaders()	
  //     // .set("Access-Control-Allow-Origin", "*")	
  //     // .set("Access-Control-Allow-Headers", "Content-Type")	
  //     .set("Authorization", "Basic " + btoa(`${environment.onDemand.clientId}:${environment.onDemand.secret}`))	
  //     .set("Content-Type", "application/json")	
  //     .set("skipAuth", "true");	
  //   return this.apiService	
  //     .post(url, null, { headers })	
  //     .pipe(	
  //       map(({ body }: any) => {	
  //         sessionStorage.setItem(this.TOKEN_KEY, body.access_token);	
  //         this.setExpiration(body.expires_in);	
  //         return body;	
  //       })	
  //   );	
  // }

  generateToken(username: string, code: string): Observable<any> {
    let url =
      environment.onDemand.baseUrl +
      environment.onDemand.apiAuth +
      "&username=" +
      username +
      "&auth_token=" +
      code +
      "&redirect_uri=" +
      environment.hostURL;

    return this.http.post(url, {}).pipe(
      map((token: any) => {
        // sessionStorage.setItem(this.TOKEN_KEY, token.access_token);
        localStorage.setItem(this.TOKEN_KEY, token.access_token);
        this.setExpiration(token.expires_in);
        return token;
      })
    );
  }

  getToken() {
    // return sessionStorage.getItem(this.TOKEN_KEY);
    return localStorage.getItem(this.TOKEN_KEY);
  }

  setExpiration(seconds: number) {
    let currentTime = new Date().getTime() / 1000;
    // sessionStorage.setItem(this.TOKEN_EXPIRE_KEY, (currentTime + seconds).toString());
    localStorage.setItem(this.TOKEN_EXPIRE_KEY, (currentTime + seconds).toString());
  }

  hasToken() {
    // return sessionStorage.getItem(this.TOKEN_KEY) !== null && !this.tokenIsExpired();
    return localStorage.getItem(this.TOKEN_KEY) !== null && !this.tokenIsExpired();
  }

  tokenIsExpired(): boolean {
    // let expirationTime = sessionStorage.getItem(this.TOKEN_EXPIRE_KEY);
    let expirationTime = localStorage.getItem(this.TOKEN_EXPIRE_KEY);
    if (expirationTime === null) return true;
    let currentTime = new Date().getTime() / 1000;
    return Number(expirationTime) - currentTime < 0;
  }
}
