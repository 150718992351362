<!-- Site Top Bar  -->
<section class="top-bar-section">
    <div class="container">
        <div class="mobile-top-bar">
            <div class="d-flex justify-content-center">
                <ul class="toggle-project">
                    <li class="active">
                        Builder Xchange
                    </li>
                    <li *ngIf="showMohawkXchangeCTA" (click)="gotoMohawkXchange()">
                        Mohawk Xchange
                    </li>
                </ul>
            </div>
            <div class="mobile-top-right-menu">
                <!-- <ul class="top-bar-menu-right">
                    <li *ngFor="let m of menuRight; let i = index;">
                        <span class="is-icon" *ngIf="m.icon">{{getAvatarLabel(m.name)}}</span>
                        <a (click)="handleAccountInfoClick(m.type)">
                            {{ m.name }} <i *ngIf="m.listIcon" [class]="m.listIcon"></i>
                        </a>
                    </li>
                </ul> -->
                <span dropdown>
                    <a href id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown">
                       <span class="mohawak-icon user-icon">
                        <i class="bi bi-arrow-down-square-fill color-white"></i>
                    </span>
                    </a>
                    <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu mobile-sub-header"
                        role="menu" aria-labelledby="basic-link">
                        <li *ngFor="let m of menuRight; let i = index;">
                            <span class="is-icon" *ngIf="m.icon">{{getAvatarLabel(m.name)}}</span>
                            <a (click)="handleAccountInfoClick(m.type)">
                                {{ m.name }} <i *ngIf="m.listIcon" [class]="m.listIcon"></i>
                            </a>
                        </li>
                    </ul>
                  </span>
                <!-- <button>
                    <span class="mohawak-icon user-icon">
                        <i class="bi bi-arrow-down-square-fill"></i>
                    </span>
                </button> -->
            </div>
        </div>
        <div class="top-bar">
            <ul class="top-bar-menu-left">
                <li>
                    <a class="active"> Builder Xchange </a>
                </li>
                <li *ngIf="showMohawkXchangeCTA" (click)="gotoMohawkXchange()">
                    <a> Mohawk Xchange </a>
                </li>
            </ul>
            <ul class="top-bar-menu-right">
                <li *ngFor="let m of menuRight; let i = index;">
                    <span class="is-icon" *ngIf="m.icon">{{getAvatarLabel(m.name)}}</span>
                    <a (click)="handleAccountInfoClick(m.type)">
                        {{ m.name }} <i *ngIf="m.listIcon" [class]="m.listIcon"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</section>

<header class="header-section">
    <div class="container">
        <header class="site-header">
            <div class="site-logo-and-search-container">

                <div class="logo" style="cursor:pointer">
                    <img src="https://s7d4.scene7.com/is/image/MohawkResidential/mohawkbuilder?fmt=webp" alt="" (click)="handleLogoClick()">
                </div>

            </div>
            <div class="site-header-cart-container">
                <div class="builder-logo-container" *ngIf="showBuilderInfo">
                    <img *ngIf="builderDetails?.builderImageUrl" [src]="builderDetails?.builderImageUrl" />
                    <h3 *ngIf="!builderDetails?.builderImageUrl">{{builderDetails?.name}}</h3>
                    <div *ngIf="!!builderDetails?.uid" class="w-100 text-center"><button class="btn btn-secondary change-builder-btn"
                            linkContainer="Global Header" (click)="handleRedirectToBuilder()"> Change
                        </button></div>
                </div>
                <!-- <div class="me-2" (click)="logout()"  style="cursor:pointer">
                    <img src="/assets/images/icons/user1.svg">
                </div> -->

                <div class="me-2" style="cursor:pointer">
                    <div class="btn-group" dropdown>
                        <img
                            src="/assets/images/icons/user1.svg"
                            id="button-basic"
                            dropdownToggle
                            type="button"
                            aria-controls="dropdown-basic"
                        />
                        <ul
                            id="dropdown-basic"
                            *dropdownMenu
                            class="dropdown-menu"
                            role="menu"
                            aria-labelledby="button-basic"
                        >
                            <li role="menuitem" *ngFor="let item of accountDropdownItems" [ngClass]="{'disabled-profile-menu': item?.isDisabled}">
                                <a class="dropdown-item" *ngIf="item.path" captureClicks linkContainer="Global Header" [routerLink]="item.path">
                                    {{ item.name }}
                                </a>
                                <a class="dropdown-item" *ngIf="item.logout" (click)="logout()">
                                    {{ item.name }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="cart-icon" captureClicks linkContainer="Global Header" linkId="Cart" routerLink="/cart"
                    style="cursor:pointer" *ngIf="showBuilderInfo">
                    <img src="/assets/images/icons/cart.svg">
                    <div class="cart-icon__count">
                        <!-- {{ cartCount | json }} -->
                        {{miniCartCount?.totalUnitCount || 0}}
                      </div>
                </div>
            </div>
        </header>
    </div>
</header>
<app-asm></app-asm>