import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { API_CONSTANTS } from '../shared/constants/api';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  ADDRESS_VALIDATION_URL = `https://qas.virtualservices.mohawkind.com/SOAQ402/vEXT.MHK.eMPower.AddressValidation.svc`;
  private miniCartValue = new BehaviorSubject<any>({});
  private syncWithIndexDB = new BehaviorSubject<any>({});

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
  ) { }
  
  validateAddress(payload: any = {}) {
    const headers = new HttpHeaders()
      .set("Authorization", "Basic " + btoa(`${environment.addressValidationClientId}:${environment.addressValidationSecret}`))
      .set("Content-Type", "application/json")
      .set("skipAuth", "true");
    return this.apiService
      .post(this.ADDRESS_VALIDATION_URL, payload, { headers }, true)
      .pipe(map(({ body }: any) => body));
  }

  setMiniCartValue(dataList: any) {
    this.miniCartValue.next(dataList);
  }

  getMiniCartValue() {
    return this.miniCartValue.asObservable();
  }

  setSyncWithIndexDB(value: any) {
    this.syncWithIndexDB.next(value);
  }

  getSetSyncWithIndexDB() {
    return this.syncWithIndexDB.asObservable();
  }

  getASMUserList(data: any = {}) {
    let url = API_CONSTANTS.asmUserList
    .replace("{customerQuery}", data.customerQuery);
    return this.apiService
      .get(url)
      .pipe(map(({ body }: any) => body));
  }

  startSession(data: any) {
    let url = API_CONSTANTS.emulateCustomer
    .replace("{customerId}", data.email)
    return this.apiService
      .post(url, {})
      .pipe(map(({ body }: any) => body));
  }

  stopSession() {
    let url = API_CONSTANTS.stopSession;
    return this.apiService.get(url, {}).pipe(map(({ body }) => body));
  }
  updateProfile(email: any) {
    // this.userService.asmUserInfo = {
    //   isAsmUser: true,
    //   asmUser: email,
    // };
    // if (email === "") {
    //   this.userService.asmUserInfo = {};
    //   const prevUser: any = localStorage.getItem("userNamesTemp");
    //   localStorage.setItem("userNames", prevUser);
    //   this.userService.setUserEmail(String(localStorage.getItem("loginUser")));

    //   localStorage.setItem("userNamesTemp", "");
    // } else {
    //   localStorage.setItem("userNames", email);
    //   this.userService.setUserEmail(email);

    //   localStorage.setItem("userNamesTemp", this.userService.getUserEmail());
    //   this.userService.asmUserInfo = {
    //     isAsmUser: true,
    //     asmUser: this.userService.getUserEmail(),
    //   };
    // }
    return this.storageService.getItem("customer-details").pipe(take(1));
  }

  getMiniCartData(data:any) {
    const url = API_CONSTANTS.miniCart
      .replace("{uid}", data?.uid)
      .replace("{customerNumber}", data?.customerNumber);
    return this.apiService.get(url).pipe();
  }
}
