import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SSOGuard } from './features/http-services/SSOGuard';
import { AuthorizedLayoutComponent } from './features/shared/layouts/authorized-layout/authorized-layout.component';
import { UnauthorizedLayoutComponent } from './features/shared/layouts/unauthorized-layout/unauthorized-layout.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: UnauthorizedLayoutComponent,
  //   loadChildren: () => import('./features/user/user.module').then(m => m.UserModule),
  // },
  {
    path: '',
    component: AuthorizedLayoutComponent,
    canActivate: [SSOGuard],
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {

    path: 'register',
    component: UnauthorizedLayoutComponent,
    loadChildren: () => import('./features/onboarding/onboarding.module').then(m => m.OnboardingModule),
  },
  { path: 'register-options',   redirectTo: '/register/register-options', pathMatch: 'full' },
  { path: 'registration',   redirectTo: '/register/registration', pathMatch: 'full' },
  { path: 'forgot-password',   redirectTo: '/register/forgot-password', pathMatch: 'full' },
  { path: 'error',   redirectTo: '/register/error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
