import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizedLayoutComponent } from './authorized-layout/authorized-layout.component';
import { BxFooterComponent } from './components/bx-footer/bx-footer.component';
import { BxHeaderComponent } from './components/bx-header/bx-header.component';
import { UnauthorizedLayoutComponent } from './unauthorized-layout/unauthorized-layout.component';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CaptureClicksDirective } from '../directives/capture-clicks.directive';
import { AsmComponent } from './components/asm/asm.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BxHeaderComponent,
    BxFooterComponent,
    AuthorizedLayoutComponent,
    UnauthorizedLayoutComponent,
    CaptureClicksDirective,
    AsmComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule
  ],
  exports: [
    BxHeaderComponent,
    BxFooterComponent,
    AuthorizedLayoutComponent,
    UnauthorizedLayoutComponent,
    CaptureClicksDirective,
    ReactiveFormsModule
  ],
})
export class LayoutsModule {}
