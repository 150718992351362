import { Component, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { first, map, mergeMap, pipe, take } from "rxjs";
import { ValidateCartModalComponent } from "src/app/features/dashboard/accounts/modals/validate-cart-modal/validate-cart-modal.component";
import { ServicesService } from "src/app/features/dashboard/accounts/pages/service/services.service";
import { Eventservice } from 'src/app/features/http-services/events.service';
import { SharedService } from "src/app/features/http-services/shared.service";
import { StorageService } from "src/app/features/http-services/storage.service";
import { TokenService } from "src/app/features/http-services/token.service";
import { profileTab } from "../../../constants/profileTab";
import { environment } from "src/environments/environment";

@Component({
  selector: "bx-header",
  templateUrl: "./bx-header.component.html",
  styleUrls: ["./bx-header.component.scss"],
})
export class BxHeaderComponent implements OnInit {
  modalRef!: BsModalRef;
  @Input() showBuilderInfo: boolean = true;
  menuRight: Array<any> = [];
  miniCartCount: any = {};
  accountDropdownItems: Array<any> = [];
  builderDetails: any = {};
  isMultiAccount:boolean=false;
  showMohawkXchangeCTA: boolean = true;
  constructor(
    private storageServcie: StorageService,
    private tokenService: TokenService,
    private sharedService: SharedService,
    private router: Router,
    private csrService: ServicesService,
    private modalService: BsModalService,
    private eventservice: Eventservice
  ) {
    this.router.events.subscribe((val) => {
      this.dataUp();
    });
  }

  customerDetails$ = this.storageServcie
    .getItem("customer-details")
    .pipe(first());

  builderDetails$ = this.storageServcie
    .getItem("selected-builder")
    .pipe(first());

  logout() {
    this.tokenService.logout();
  }
  ngOnInit(): void {
    this.accountDropdownItems = profileTab;
    this.storageServcie.getItem("cart").subscribe((res) => {
      if (Object.keys(res || {}).length > 0) {
        this.miniCartCount = res;
      }
    });
    this.sharedService.getMiniCartValue().subscribe((res) => {
      if (Object.keys(res || {}).length > 0) {
        this.miniCartCount = res;
      }
    });
    this.dataUp();
    this.builderDetails$.subscribe({
      next: res => this.builderDetails = res || {}
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.showMohawkXchangeCTA = this.router.url !== "/shipment-report";
        this.builderDetails$.subscribe({
          next: res => this.builderDetails = res || {}
        }); 
      }
    });
    this.storageServcie.getItem("is-multi-accounts").subscribe(res => {
      if(res) {
        const profileTabs = this.accountDropdownItems;
        profileTabs.splice(1, 0, {
            name: 'Account List',
            path: '/multiple-accounts'
        });
        this.accountDropdownItems = profileTabs;
      }
    });

    this.sharedService.getSetSyncWithIndexDB().subscribe((res) => {
      console.log("SYNCING_WITH_INDEX_DB");
      this.dataUp();
    });
  }
  dataUp(){
    this.customerDetails$.subscribe(({ isCSR, b2bUnitAddress, orgUnit,accountList, isSalesPerson, isSalesOpsUser }) => {
      this.isMultiAccount=accountList
      
      this.menuRight = [
        {
          name: orgUnit?.name || "",
          icon: orgUnit?.uid !== "EMPTY_B2BUNIT",
          link: "",
        },
        {
          name: `Account # ${orgUnit?.accountType || ""}.${orgUnit?.accountNumber || ""}.${orgUnit?.suffix || ""}`,
        },
        {
          name: b2bUnitAddress?.formattedAddress || "",
        },
      ];

      if (isCSR && orgUnit?.uid !== 'EMPTY_B2BUNIT') {
        this.menuRight.push({ type: 'exit', name: 'Exit', listIcon: "bi bi-chevron-right" })
      }
      if (isSalesPerson || isSalesOpsUser) {
        this.storageServcie.getItem(["salesman-selected"])
          .pipe(take(1))
          .subscribe(([salesmanSelected]) => {
            if ((salesmanSelected) && !this.menuRight.some((item) => item.type === 'exit')) {
              this.menuRight.push({ type: 'exit', name: 'Exit', listIcon: "bi bi-chevron-right" })
            }
        });
      }
      if (isCSR) {
        this.accountDropdownItems = this.accountDropdownItems.map((item: any) => {
          if (item?.path === "/my-profile") {
            item.isDisabled = orgUnit?.uid === 'EMPTY_B2BUNIT'
          }
          return {
            ...item,
          }
        });
      }
      if (isSalesPerson || isSalesOpsUser) {
        const profileTabs = this.accountDropdownItems;
        const isSlaesmanOptionExist = profileTabs.some((item => item.name === "Salesperson Dashboard"));
        const isSlaesmanAccountsExist = profileTabs.some((item => item.name === "Accounts"));

        if (isSalesOpsUser) {
          if (!isSlaesmanAccountsExist) {
            profileTabs.splice(1, 0, {
              name: 'Accounts',
              path: '/salesman-view-account'
            });
          } 
        } else {
          if (!isSlaesmanOptionExist) {
            profileTabs.splice(1, 0, {
              name: 'Accounts',
              path: '/salesman-view-account'
            },
            {
              name: 'Salesperson Dashboard',
              path: '/salesman-dashboard'
          });
          }
        }
        this.accountDropdownItems = profileTabs;
      }
    });
    this.builderDetails$.subscribe({
      next: res => {
        this.builderDetails = res || {}
      }
    });
  }

  handleAccountInfoClick(type:string){
      if(type === 'exit'){
        this.storageServcie.getItem(["customer-details", "cart"])
        .pipe(take(1))
        .subscribe(([customerDetails, cart]) => {
          if (cart?.totalUnitCount && !(customerDetails?.isSalesPerson || customerDetails?.isSalesOpsUser)) {
            this.openValidateCSRCartModal({
              cartId: cart.code,
              userId: customerDetails?.uid
            });
          } else {
            if (customerDetails?.isSalesPerson || customerDetails?.isSalesOpsUser) {
              this.storageServcie.removeItem("cart");
              this.sharedService.setMiniCartValue({ code: null, totalUnitCount: 0 });
              this.router.navigate(['/salesman-dashboard']);
            } else {
              this.router.navigate(['/csr'], { queryParams: { exit: true }})
            }
            this.dataUp();
          }
        })
        // this.router.navigate(['/csr'], { queryParams: { exit: true }})
        // this.dataUp()
      }
      // if(name.includes('Account #') && this.isMultiAccount){
      //   this.router.navigate(['/multiple-accounts'])
      //   this.dataUp()
      // }
    }

    handleLogoClick() {
      this.eventservice.internalLinkClicked({
        linkContainer: 'Global Header',
        linkId: 'Home',
        linkTarget: '/',
      });
      this.storageServcie.getItem(["customer-details", "salesman-selected"])
      .pipe(take(1))
      .subscribe(([customerDetails, salesmanSelected]) => {
        this.router.navigate([((customerDetails?.isSalesPerson || customerDetails?.isSalesOpsUser) && !salesmanSelected) ? "/salesman-dashboard" : "/"]);
      });
      
    }

    openValidateCSRCartModal(data = {}) {
      const initialState: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
        initialState: {
          ...data,
        },
      };
      this.modalRef = this.modalService.show(
        ValidateCartModalComponent,
        Object.assign(initialState, { class: `modal-md modal-dialog-centered` })
      );
    }

    getAvatarLabel(name: any = "") {
      const nameArr = (name || "").split(" ");
      return `${nameArr?.[0]?.[0] || ""}${nameArr?.[1]?.[0] || ""}`
    }

    gotoMohawkXchange() {
      window.open(environment.mohawkXchangeAppUrl, "_blank");
    }

    handleRedirectToBuilder() {
      if (this.router.url === "/shipment-report") {
        this.router.navigate(['/select-builder'], { queryParams: { active: true }});
      } else {
        this.router.navigate(['/select-builder']);
      }
    }
  
}