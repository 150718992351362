import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { StorageService } from 'src/app/features/http-services/storage.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/app/features/shared/constants/misc';
import { ConfirmationDialogComponent } from '../../../orders/modals/confirmation-dialog/confirmation-dialog.component';
import { OrdersService } from '../../../orders/services/orders.service';
import { ServicesService } from '../../pages/service/services.service';

@Component({
  selector: 'app-validate-cart-modal',
  templateUrl: './validate-cart-modal.component.html',
  styleUrls: ['./validate-cart-modal.component.scss']
})
export class ValidateCartModalComponent implements OnInit {

  modalRef!: BsModalRef
  cartId: any;
  userId: any;
  constructor(
    private modalService: BsModalService,
    private ordersService: OrdersService,
    private router: Router,
    private csrService: ServicesService,
    private storageService: StorageService
  ) { }

  cancelCart$(data: any) {
    return this.ordersService.cancelCart(data); 
  }

  saveCSRCart$(data: any) {
    return this.csrService.csrSaveCart(data); 
  }

  handleDiscard() {
    this.cancelCart$({ username: this.userId, cartId: this.cartId})
    .pipe(take(1))
    .subscribe({
      next: res => {
        this.modalService.hide();
        this.storageService.removeItem("cart");
        this.router.navigate(['/csr'], { queryParams: { exit: true }});
      },
      error: err => {
        const errorMessage  = (err?.error?.errors || [])[0]?.message || DEFAULT_ERROR_MESSAGE;
        this.openConfirmationModal({
          title: "Error",
          content: errorMessage,
          primaryActionLabel: "Ok",
          disableSecondaryAction: true,
        });
        this.router.navigate(['/csr'], { queryParams: { exit: true }});
      }
    });
  }

  handleSave() {
    this.saveCSRCart$({ username: this.userId, cartId: this.cartId})
    .pipe(take(1))
    .subscribe({
      next: res => {
        this.modalService.hide();
        this.router.navigate(['/csr'], { queryParams: { exit: true }});
      },
      error: err => {
        const errorMessage  = (err?.error?.errors || [])[0]?.message || DEFAULT_ERROR_MESSAGE;
        this.openConfirmationModal({
          title: "Error",
          content: errorMessage,
          primaryActionLabel: "Ok",
          disableSecondaryAction: true,
        });
        this.router.navigate(['/csr'], { queryParams: { exit: true }});
      }
    });
  }

  ngOnInit(): void {
  }


  openConfirmationModal(data = {}, size: any = 'md') {
    const initialState: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        ...data,
        // modalId: "csr-error",
      },
    };
    this.modalRef = this.modalService.show(
      ConfirmationDialogComponent,
      Object.assign(initialState, { id: "csr-error", class: `modal-${size} modal-dialog-centered` })
    );
  }

}
