import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/features/http-services/api.service';
import { API_CONSTANTS } from 'src/app/features/shared/constants/api';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private apiService: ApiService) { }
  // csrSearch:"services/v2/{baseSiteId}/users/{userId}/findAccount/csr?filterTab={tabName}&page={pageNumber}&showMode={Page/All}&sortCode={documentNumber}"
// services/v2/mhkflooringBMF/users/purushottambvalasa@gmail.com/findAccount/csr?filterTab=#customerNumberSearch
  getSearchList( data: any, payload: any) {
    console.log(data);
    console.log(payload);
    
    
    const endpoint = API_CONSTANTS.csrSearch
      .replace("{userId}", data.username)
      .replace("{tabName}", data.tabNames)
      .replace("{pageNumber}", data.pageNumber)
    // console.log(endpoint, payload)

    return this.apiService.post(endpoint, payload).pipe(map(({ body }: any) => body));
  }
  selectCsr(data:any,payload:any){
    
    const endpoint = API_CONSTANTS.selectCsr
      .replace("{userId}", data.username)
      .replace("{B2BUnitId}", data.B2BUnitId)

    return this.apiService.post(endpoint,payload).pipe(map(({ body }: any) => body));
  }
  exitCSR(data:any,payload:any){

    const endpoint = API_CONSTANTS.exitCsr
      .replace("{userId}", data.username)
      .replace("{B2BUnitId}", data.B2BUnitId)

    return this.apiService.post(endpoint,payload).pipe(map(({ body }: any) => body));
  }

  csrValidateAccount(data: any) {
    const endpoint = API_CONSTANTS.csrValidateAccount
      .replace("{userId}", data.username)
      .replace("{invoiceAccount}", data.invoiceAccount);
    return this.apiService.get(endpoint, {}).pipe(map(({ body }: any) => body));
  }

  csrSaveCart(data: any) {
    const endpoint = API_CONSTANTS.csrSaveCart
      .replace("{userId}", data.username)
      .replace("{cartId}", data.cartId);
    return this.apiService.get(endpoint, {}).pipe(map(({ body }: any) => body));
  }
}
