import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { catchError, map, mergeMap, Observable, of, switchMap, take } from "rxjs";
import { UserService } from "../dashboard/user/services/user.service";
import { StorageService } from "./storage.service";
import { TokenService } from "./token.service";
import { SharedService } from "./shared.service";

@Injectable({
  providedIn: "root",
})
export class SSOGuard implements CanActivate {
  constructor(
    private router: Router,
    private http$: HttpClient,
    private tokenService: TokenService,
    private userService: UserService,
    private storageService: StorageService,
    private sharedService: SharedService
  ) {}

  setDefaultUnit$(username: any) {
    return this.userService.setDefaultUnit({ username }).pipe(take(1));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    
    if (this.tokenService.hasToken()) {
      // this.updateCustomerDetails();
      return of(true);
    }
    if (this.tokenCanBeGeneratedFromCurrentURL(route)) {
      let username = route.queryParams["state"];
      let code = route.queryParams["code"];
      localStorage.setItem("userNames", username);
      this.userService.setUserEmail(username);
      console.log("code iss---->",code)
    return this.tokenService
        .generateToken(username, code)
        .pipe(
          switchMap(() => {
            return this.userService.getDefaultPath();
          }),
          switchMap(() => {
            return this.userService.getCustomerDetails({
              username,
              addInIndexDB: true,
            });
          })
        )
        .pipe(
          switchMap((defaultPath) => {
            if (defaultPath?.isCSR || defaultPath?.isSalesPerson || defaultPath?.isSalesOpsUser) {
              return of({ accounts: [], defaultPath });
            }
            return this.userService.getAccounts({ userId: defaultPath?.uid })
            .pipe(map(accounts => ({
              accounts,
              defaultPath
            })))
          }),
          switchMap(({ accounts, defaultPath}) => {
            if (accounts?.length) {
              this.router.navigate(['/multiple-accounts']);
              this.storageService.setItem('is-multi-accounts', true)

              return of(true);
            }
            if (defaultPath?.isCSR || defaultPath?.isSalesPerson || defaultPath?.isSalesOpsUser) {
              this.setDefaultUnit$(defaultPath?.uid).subscribe();
              this.router.navigate([(defaultPath?.isSalesPerson || defaultPath?.isSalesOpsUser) ? '/salesman-dashboard' : '/csr']);
              return of(true);
            }
            this.removeParamsFromURL(route, state);
            let url: string | null = defaultPath;
            if (
              sessionStorage.getItem("redirectURL") !== "/" &&
              sessionStorage.getItem("redirectURL") !== null
            ) {
              url = sessionStorage.getItem("redirectURL");
              sessionStorage.removeItem("redirectURL");
            }
            let directory = url?.split("?")[0];
            let params = url?.split("?")[1] || "";
            let formattedParams = new URLSearchParams(params);
            this.router.navigate([directory], { queryParams: formattedParams });

            return of(true);
          }),
          catchError(err => {
            const error = err?.error?.error;
            if (error === "invalid_grant") {
              this.router.navigate(['/error']);
              return of(false);
            }
            return of(true);
          }),
        );
      //  return of(true);
       
    } else {
      this.tokenService.redirectToLogin(state.url);
      return of(false);
    }
  }

  removeParamsFromURL(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let queryParams = JSON.parse(JSON.stringify(route.queryParams));

    delete queryParams["code"];
    delete queryParams["state"];
    delete queryParams["iss"];
    delete queryParams["client_id"];

    this.router.navigate([state.url.substring(0, state.url.indexOf("?"))], {
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }

  tokenCanBeGeneratedFromCurrentURL(route: ActivatedRouteSnapshot): boolean {
    return (
      route.queryParams["code"] !== undefined &&
      route.queryParams["state"] !== undefined
    );
  }
  
  buildersList$(invoiceAccountUnitId: any, userId: any) {
    return this.userService.getBuilderListing(invoiceAccountUnitId, userId)
    .pipe(take(1))
  }
  
  updateCustomerDetails() {
    this.storageService
    .getItem(["customer-details", "selected-builder"])
    .pipe(take(1))
    .subscribe(([customerDetails]) => {
      if (customerDetails?.isCSR || customerDetails?.isSalesPerson || customerDetails?.isSalesOpsUser) {
        this.userService.getCustomerDetails({
          username: customerDetails?.uid,
          addInIndexDB: true
        }).subscribe({
          next: res => {
            setTimeout(() => {
              this.sharedService.setSyncWithIndexDB(Date.now());
            }, 1000);
            if (res?.orgUnit?.name === "Empty B2BUnit") {
              this.router.navigate([customerDetails?.isCSR ? '/csr' : '/salesman-dashboard']);
            } else if (customerDetails?.orgUnit?.accountNumber !== res?.orgUnit?.accountNumber) {
                this.storageService.removeItem("selected-builder");
                this.router.navigate(['/select-builder']);
            }
          }
        })
      }
    });
  }
}
