<footer class="footer-section">
    <div class="container">
        <div class="footer-content">
            <div>
                <ul class="top-bar-menu-right footer-menu">
                    <li> <a captureClicks linkContainer="Global Footer" href="https://mohawkind.com/legal.php" target="_blank"> Legal Terms
                            and Conditions </a></li>
                    <li> <a captureClicks linkContainer="Global Footer" href="https://mohawkind.com/privacy-policy.php" target="_blank">
                            Privacy Policy </a></li>
                    <li> <a captureClicks linkContainer="Global Footer" [routerLink]="'/contacts'"> Contact Us </a></li>
                </ul>
                <p class="copy-right-text mt-2 mb-0">© 2023 Mohawk Industries, Inc.
                </p>
            </div>


            <div captureClicks linkContainer="Global Footer" linkId="Home" routerLink="/">
                <img src="/assets/images/mohawak-logo-light.svg" alt="">
            </div>
        </div>
    </div>
</footer>