import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, finalize, mergeMap, Observable, of, take, throwError } from 'rxjs';
import { TokenService } from './token.service';
import { StorageService } from './storage.service';
import { DEFAULT_ERROR_MESSAGE } from '../shared/constants/misc';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../dashboard/orders/modals/confirmation-dialog/confirmation-dialog.component';
import { INVOICE_HEADER_BLACKLIST_ENDPOINTS } from '../shared/constants/invoice-header-blacklist-endpoints';
import { environment } from 'src/environments/environment';
import { UserService } from '../dashboard/user/services/user.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  modalRef!: BsModalRef;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private storageService: StorageService,
    private modalService: BsModalService,
    private userService: UserService,
    private sharedService: SharedService,
  ) {}
  
  invalidTokenError(error: HttpErrorResponse): boolean {
    let errorsArray = error.error.errors;
    if (errorsArray === undefined) return false;
    if (errorsArray.find((object: any) => object.type === "InvalidTokenError")) {
      return true;
    }
    return false;
    
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const shouldAddToken = !request.headers.has("skipAuth");
    if (!shouldAddToken) {
      request = request.clone({ headers: request.headers.delete("skipAuth")});
    }
    if (this.tokenService.hasToken() && shouldAddToken) {
      return this.currentConfig$()
        .pipe(
          take(1),
          mergeMap(config => {
            return next
              .handle(
                request.clone({
                  setHeaders: this.getReqHeaders(config, request),
                })
              )
              .pipe(
                catchError((error) => {
                  // Treat error as success if it is a 200 response with invalid JSON
                  if (
                      error instanceof HttpErrorResponse &&
                      error.status === 200 &&
                      ((error.error?.error?.message || "").includes("not valid JSON") ||
                      (error.error?.error?.message || "").includes("JSON.parse: unexpected"))
                    ) {
                      const response = new HttpResponse({
                        body: error?.error?.text,
                        status: 200,
                        statusText: 'OK'
                      });
                      return of(response);
                  }

                  if (this.invalidTokenError(error)) {
                    // this.tokenService.redirectToLogin(this.router.url);
                    window.location.href = "./";
                  }
                  // Modify message if errorcode 0002, it is for multi browser login
                  const errorCode = error?.error?.errors?.[0]?.subject;
                  if (errorCode === '0002' && (error?.error?.errors || [])[0]?.message) {
                    error.error.errors[0].message = `You have logged in to more than one browser with different ${config?.isCSR ? 'retailers' : 'accounts'}. Please select the account again`;
                  }
                  this.handleError(error, config);
                  return throwError(() => error);
                })
              );
          })
        )
    }
    return next.handle(request);
  }

  openConfirmationModal(data: any = {}) {
    const initialState: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        ...data,
      },
    };
    this.modalRef = this.modalService.show(
      ConfirmationDialogComponent,
      Object.assign(initialState, {id: data?.modalId || "", class: "modal-md modal-dialog-centered" })
    );
  }

  handleError(error: any, config: any) {
    const errorCode = error?.error?.errors?.[0]?.subject;
    if (errorCode === '0002') {
      const errorMessage  = `You have logged in to more than one browser with different ${config?.isCSR ? 'retailers' : 'accounts'}. Please select the account again`;
      if (config?.isCSR || config?.isSalesOpsUser || config?.isSalesPerson) {
        this.userService.setDefaultUnit({
          username: config?.uid
        }).pipe(
          take(1),
          finalize(() => {
            this.storageService.removeItem("cart");
            this.storageService.removeItem("selected-builder");
            this.storageService.removeItem("salesman-selected");
            this.userService.getCustomerDetails({
              username: config?.uid,
              addInIndexDB: true
            }).pipe(
              take(1),
              finalize(() => {
                setTimeout(() => {
                  this.sharedService.setSyncWithIndexDB(Date.now());
                  if (config?.isCSR) {
                    this.router.navigate(['/csr'], { queryParams: { exit: true }});
                  } else {
                    this.router.navigate(["/salesman-view-account"]);
                  }
                  this.openConfirmationModal({
                    title: "Error",
                    content: errorMessage,
                    primaryActionLabel: "Ok",
                    disableSecondaryAction: true,
                    onPrimaryAction: () => {
                      window.location.reload();
                    },
                  });
                }, 1000);
              })
            ).subscribe();
          })
        ).subscribe()
      }
    }
  }

  getReqHeaders(config: any, request: any) {
    const endpoint = request.urlWithParams;
    const invoiceAccountUid = config?.orgUnit?.uid;
    const isEndpointPatternBlacklisted = INVOICE_HEADER_BLACKLIST_ENDPOINTS.some(item => {
      // @ts-ignore
      const pattern = new URLPattern(item, environment.apiBaseUrl);
      return pattern.test(endpoint);
    });
    const reqHeaders: any = {
      Authorization: "Bearer " + this.tokenService.getToken()
    }
    if (invoiceAccountUid && invoiceAccountUid !== 'EMPTY_B2BUNIT' && !isEndpointPatternBlacklisted) {
      reqHeaders['invoiceAccount'] = invoiceAccountUid;
    }
    return reqHeaders;
  }

  currentConfig$() {  
    return this.storageService
      .getItem("customer-details")
      .pipe(take(1))
  }
}
