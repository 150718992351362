<div class="modal-header text-center">
  <h4 class="modal-title f-w w-100 text-uppercase">Did you forget something?</h4>
</div>
<div class="modal-body p-20">
  <div class="_card white-bg">
      <div class="row">
          <div class="col-md-12 mb-3">
            <h3>You have items in your cart</h3>
            <h3>Would you like to save this session to return later?</h3>
          </div>
      </div>
  </div>
  <div class="footer">
      <button
        class="btn btn-secondary"
        (click)="handleDiscard()"
      >
        Discard
      </button>
      <button
        class="btn btn-primary"
        (click)="handleSave()"
      >
        Save
        </button>
  </div>
</div>