import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { StorageService } from "src/app/features/http-services/storage.service";
import { SharedService } from "src/app/features/http-services/shared.service";

@Component({
  selector: 'app-asm',
  templateUrl: './asm.component.html',
  styleUrls: ['./asm.component.scss']
})
export class AsmComponent implements OnInit {
  myForm!: FormGroup;
  suggestions: any[] = [];
  showSuggestions: boolean = false;
  isSuggestionSelected: boolean = false;
  hasCarts: boolean = false;
  startSession: boolean = false;
  showCart: boolean = false;
  cartData: any = [];
  userStartedTyping: boolean = false;
  showErrorMessage = false;
  spinnerLoading = false;
  errorMessage = "";
  @ViewChild("cartInput") cartInput!: ElementRef;
  preserveCardData: any;
  isASMUser: any = false;
  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private storageService: StorageService,

  ) {}
  isFormVisible = true;
  submitButtonEnable=false;
  @HostListener('window:resize', ['$event'])
  onResize(event  :any) {
    if (event.target.innerWidth < 768) {
      this.isFormVisible = true;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event : any) {
    this.renderer.listen('window', 'scroll', () => {
      if (window.innerWidth < 768) {
        const mainPage = document.getElementById('mainPage');
        if (mainPage) {
          const scrollTop =  document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop > 400) {
            this.isFormVisible = false;
          } else {
            this.isFormVisible = true;
          }
        } 
      }
    });
  }
  ngOnInit(): void {
    this.createForm();
    this.storageService.getItem("customer-details").subscribe((response: any) => {
      this.isASMUser=response.isASMUser;
    })
     
    const storedSessionState = sessionStorage.getItem("startSession");
    if (storedSessionState === "true") {
      this.startSession = true;
      this.submitButtonEnable = true;
      const storedEmail = sessionStorage.getItem("storedEmail");
      const storedCart = sessionStorage.getItem("storedCart");

      if (storedEmail) {
        this.myForm.get("email")?.setValue(storedEmail);
        this.myForm.get("email")?.disable();
      }
    }
  }

  createForm() {
    this.myForm = this.fb.group({
      email: [
        { value: "", disabled: false },
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      cart: [{ value: "", disabled: false }],
    });
  }

  onFormSubmit() {
    this.myForm.get("email")?.enable();
    this.myForm.get("cart")?.enable();
   
    if(!this.submitButtonEnable){
      return
    }
    
    if (this.myForm.valid) {
      this.spinnerLoading = true;
      this.showErrorMessage = false;
      this.errorMessage = "";
      if (!this.startSession) {
        this.sharedService.startSession(this.myForm.value).subscribe({
          next: (res) => {
            this.spinnerLoading = false;
            if (res?.status == "success") {
              this.startSession = true;
              sessionStorage.setItem("storedEmail", this.myForm.value.email);
              sessionStorage.setItem("storedCart", this.myForm.value.cart);
              this.showCart = false;
              sessionStorage.setItem("startSession", "true");
              this.updateProfile(this.myForm.value.email);
              this.myForm.get("email")?.disable();
              this.myForm.get("cart")?.disable();
            } else {
              this.showErrorMessage = true;
              this.errorMessage = res?.message;
            }
          },
        });
      } else {
        this.sharedService.stopSession().subscribe({
          next: (res) => {
            this.spinnerLoading = false;
            if (res?.status == "success") {
              this.startSession = false;
              sessionStorage.removeItem("startSession");
              sessionStorage.removeItem("storedEmail");
              sessionStorage.removeItem("storedCart");
              this.myForm.reset();
              this.updateProfile("");
            } else {
              this.showErrorMessage = true;
              this.errorMessage = res?.message;
            }
          },
        });
      }
    }
  }
  updateProfile(email: any) {
    this.sharedService.updateProfile(email).subscribe({
      next: (res) => {
        sessionStorage.setItem("isUidSet", "true");
        
        // TODO
        // this.sharedService.getMiniCart(res?.body?.orgUnit?.uid);
        let custUid = res?.body?.accounts?.uid;
        if (res?.body?.isCustomer === true && custUid === undefined) {
          custUid = res?.body?.accounts?.[0]?.uid;
        }
        if (
          !(res?.body?.accounts?.length > 1) &&
          res?.body?.accounts?.length !== undefined &&
          res?.body?.accounts?.length !== 0
        ) {
          // TODO
          // this.sharedService.navigateUsers(res?.body);
        } else {
          // TODO
          // this.sharedService.navigateUsers(res?.body);
        }
      },
    });
  }

  onChangeValue(customerQueries: any) {
    this.showCart = false;
    this.isSuggestionSelected = false;
    let query = customerQueries.value;

    if (typeof query === "string") {
      query = query.trim();

      this.showSuggestions = query.length >= 3;

      if (query.length >= 3) {
        this.sharedService.getASMUserList({ customerQuery: query }).subscribe(
          (res) => {
            this.suggestions = res;
            
            if(res?.error){
              this.submitButtonEnable=false;
            }else{
              this.submitButtonEnable=true;
            }
            this.showSuggestions = true;
           
          },
          (error) => {
            this.submitButtonEnable=false;
          }
        );
      } else {
        this.suggestions = [];
      }
    } else {
      this.suggestions = [];
      this.showSuggestions = false;
    }
  }
  selectSuggestion(suggestion: any) {
    this.myForm.patchValue({
      email: suggestion.email,
    });
    this.suggestions = [];
    this.showSuggestions = false;
    this.cartData = suggestion?.carts;
    this.preserveCardData = [...this.cartData];
    if (this.cartData) {
      this.showCart = true;
      this.cartInput.nativeElement.focus();
    }
  }
  selectCart(carts: any) {
    this.myForm.patchValue({
      cart: carts,
    });
    this.showCart = false;
  }
  onChangeCartValue(cartValue: any) {
    if (this.cartData.length == 0 && !this.userStartedTyping) {
      this.showCart = false;
    } else if (this.cartData.length && this.userStartedTyping) {
      this.showCart = true;
    }
  }

  onCartInput(inputValue: any) {
    this.cartData = [...this.preserveCardData];
    this.userStartedTyping = inputValue.value.length > 0;
    if (this.userStartedTyping) {
      const filteredCarts = this.cartData.filter((cart: any) =>
        cart.toLowerCase().includes(inputValue.value)
      );
      this.cartData = filteredCarts;
      this.showCart = filteredCarts.length > 0;
    } else {
      this.cartData = [...this.preserveCardData];
      this.showCart = true;
    }
  }

  clearCartinput() {
    this.cartData = [...this.preserveCardData];
    this.myForm.patchValue({
      cart: "",
    });
    this.userStartedTyping = false;
    this.showCart = true;
  }
}
