<div class="modal-header text-center">
  <h4 class="modal-title f-w w-100 text-uppercase">{{title}}</h4>
</div>
<div class="modal-body p-20">
  <div class="_card white-bg">
      <div class="row">
          <div class="col-md-12 mb-3">
            <h2>{{content}}</h2>
          </div>
      </div>
  </div>
  <div class="footer">
      <button
        *ngIf="!disableSecondaryAction"
        class="btn btn-secondary"
        (click)="handleAction('secondary')"
      >
        {{secondaryActionLabel}}
      </button>
      <button
        class="btn btn-primary"
        (click)="handleAction('primary')"
      >
        {{primaryActionLabel}}
        </button>
  </div>
</div>